/** @jsx jsx */
import React from "react"
import { jsx } from "@emotion/core"
import { Button, Box, Text, Flex } from "theme-ui"
import { Formik, FormikTouched, FormikErrors } from "formik"
import { Input, inputColors } from "./UI/Input"

interface Props {
  onSubmit: (values: FormValues, bag: any) => void
}
// DOMAttributes<HTMLDivElement>.onSubmit
const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export interface FormValues {
  givenName?: string
  familyName?: string
  phone?: string
  postalCode?: string
  email?: string
}

export const AttendanceForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Formik<FormValues>
      initialValues={{
        givenName: "",
        familyName: "",
        phone: "",
        postalCode: "",
        email: "",
      }}
      validate={values => {
        if (!(values.email && values.email.match(emailRegexp))) {
          return { email: "Please enter a valid email" }
        }
        return {}
      }}
      onSubmit={(values, bag) => {
        onSubmit(values, bag)
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        errors,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Flex
              sx={{
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
                mx: "auto",
              }}
            >
              <Flex
                sx={{
                  width: "100%",
                  flexDirection: ["column", "row"],
                  alignItems: "space-between",
                }}
              >
                <Box sx={{ mr: [0, 3], flexGrow: 1 }}>
                  <Input
                    name="givenName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="First Name"
                  />
                  <Error name="givenName" {...{ touched, errors }} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Input
                    name="familyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Last Name"
                  />
                  <Error name="familyName" {...{ touched, errors }} />
                </Box>
              </Flex>
              <Box sx={{ width: "100%", mt: 3 }}>
                <Input
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(touched.email && errors.email) as any}
                  value={values.email}
                  label="Email *"
                />
                <Error name="email" {...{ touched, errors }} />
              </Box>
              <Box sx={{ width: "100%", mt: 3 }}>
                <Input
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Phone"
                />
                <Error name="phone" {...{ touched, errors }} />
              </Box>
              <Box sx={{ width: "100%", mt: 3 }}>
                <Input
                  name="postalCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Postal Code"
                />
                <Error name="postalCode" {...{ touched, errors }} />
              </Box>

              <Button
                type="submit"
                onClick={_e => handleSubmit()}
                sx={{ width: "100%", py: 3, my: 4, mx: "auto" }}
              >
                Submit
              </Button>
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

const Error: React.FC<{
  name: string
  touched: FormikTouched<FormValues>
  errors: FormikErrors<FormValues>
}> = ({ name, touched, errors }) =>
  touched[name] && errors[name] ? (
    <Text sx={{ fontSize: 1, color: inputColors.error, mb: 2 }}>
      {errors[name]}
    </Text>
  ) : null
