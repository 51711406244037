/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import React from "react"

import { Box, Text, css } from "theme-ui"
import { themeGet } from "../../util/themeHelper"

type InputStyle = "border" | "underline"

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  /* An error message string - presence indicates error */
  error?: string
  /* Input style- default: border  */
  inputStyle?: "border" | "underline"
}

export const inputColors = {
  error: "red",
  disabled: "gray.4",
  focus: "black",
  normal: "gray.2",
}

export const Input: React.FC<TextInputProps> = props => {
  const {
    type = "text",
    inputStyle = "border",
    name,
    disabled,
    error,
    label,
    ...restProps
  } = props
  let restingColor = inputColors.normal
  if (error) {
    restingColor = inputColors.error
  } else if (disabled) {
    restingColor = inputColors.disabled
  }
  return (
    <Box
      sx={{
        position: "relative",
        my: 2,
        width: "100%",
      }}
    >
      <label htmlFor={name}>
        <Text
          sx={{
            bg: "background",
            color: disabled ? inputColors.disabled : inputColors.focus,
            fontFamily: "sans",
            fontSize: 0,
            px: 1,
            position: "absolute",
            top: "-.45rem",
            left: ".5rem",
          }}
        >
          {label}
        </Text>
      </label>
      <RawInput
        type={type}
        name={name}
        baseColor={restingColor}
        inputStyle={inputStyle}
        disabled={disabled}
        error={Boolean(error)}
        // Any other input-specific props can land in here.
        {...(restProps as any)}
      />
    </Box>
  )
}

// everything below is being revamped
/**
 * A standard text input (or override with type="password, email, etc")
 * Custom props include:
 * noBorder: Render on a line instead of inside a border
 * borderColor: Border color (excluding disabled & error states)
 * label: text for `<label>` tag above input
 */

interface InputElementProps {
  inputStyle: InputStyle
  baseColor: string
  /** `true` or a string of length > 0 indicates the presence of an error */
  error: boolean | string
  disabled: boolean
}

const RawInput = styled("input")<InputElementProps>(
  // base styles
  [
    ({ theme }) =>
      css({
        boxSizing: "border-box",
        border: "none",
        width: "100%",
        bg: "background",
        fontSize: 2,
        lineHeight: 1, //todo: use theme
        fontFamily: "sans",
        px: 2,
        py: 3,
        "::placeholder": {
          color: inputColors.disabled,
          lineHeight: 1,
        },
      })(theme),

    // Account for border vs underline styles, so need a function w/ access to props
    ({ inputStyle: inputStyle, baseColor, theme }) => {
      if (inputStyle === "border") {
        return css({
          borderWidth: "2px",
          borderStyle: "solid",
          borderRadius: 3,
          borderColor: baseColor,
        })(theme)
      }
      if (inputStyle === "underline") {
        return css({
          pb: 0,
          px: 0,
          mx: 2,
          borderWidth: "0 0 2px 0",
          borderStyle: "solid",
          borderColor: baseColor,
        })(theme)
      }
    },
    {
      "&:focus": {
        borderColor: themeGet("colors." + inputColors.focus),
        outlineWidth: 0,
      },
    },
  ]
)
