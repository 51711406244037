/* eslint-disable @typescript-eslint/camelcase */
/** @jsx jsx */
import { useContext } from "react"
import { jsx } from "@emotion/core"
import { graphql, Link } from "gatsby"
import { Layout } from "../components/Layout"
import { AttendanceForm, FormValues } from "../components/AttendanceForm"
import { Styled } from "theme-ui"
import axios from "axios"
import { ModalContext } from "../components/ModalManager"
import { Logger } from "../util/logger"
import { Box } from "theme-ui"
import React from "react"
import moment from "moment"

const logger = new Logger("EventCheckIn.tsx")

export default ({ data }) => {
  const { actionNetworkEvent: event } = data
  const { setModalType } = useContext(ModalContext)

  const handleSubmit = (values, formik: any) => {
    logger.info("handleSubmit")
    axios
      .post(event.recordAttendanceHelper, actionNetworkAttendanceValues(values))
      .then(() => {
        setModalType("success")
      })
      .catch(() => {
        setModalType("networkError")
      })
      .finally(() => {
        formik.setSubmitting(false)
      })
  }

  if (event.status !== "confirmed") {
    return (
      <Layout title="Here">
        <Box sx={{ textAlign: "center" }}>
          <Styled.h2>Event not found.</Styled.h2>
          <Link to="/">
            <Styled.h4 sx={{ color: "primary", mt: 1 }}>(Try again?)</Styled.h4>
          </Link>
        </Box>
      </Layout>
    )
  } else if (moment(event.startDate).isBefore(moment(), "days")) {
    return (
      <Layout>
        {" "}
        <Box sx={{ textAlign: "center" }}>
          <Styled.h2>This event has passed.</Styled.h2>
          <Link to="/">
            <Styled.h4 sx={{ color: "primary", mt: 1 }}>(Try again?)</Styled.h4>
          </Link>
        </Box>
      </Layout>
    )
  } else
    return (
      <Layout>
        <React.Fragment>
          <Styled.h2 sx={{ color: "text" }}>Check-In</Styled.h2>
          <Styled.h3 sx={{ color: "text", mb: 1 }}>{event.title}</Styled.h3>
          <Link to="/">
            <Styled.h4 sx={{ color: "primary", mt: 1 }}>
              (Wrong event?)
            </Styled.h4>
          </Link>
          <Styled.h4 sx={{ color: "primary", mt: 1 }}>
            For more information, check&nbsp;
            <Styled.a target="_blank" rel="noopener" href={event.browserUrl}>
              the event website.
            </Styled.a>
          </Styled.h4>
          <AttendanceForm onSubmit={handleSubmit} />
        </React.Fragment>
      </Layout>
    )
}

export const pageQuery = graphql`
  query EventCheckInPageQuery($slug: String!) {
    actionNetworkEvent(shortCode: { eq: $slug }) {
      startDate
      title
      recordAttendanceHelper
      status
      browserUrl
    }
  }
`
// https://actionnetwork.org/docs/v2/record_attendance_helper
const actionNetworkAttendanceValues = (values: FormValues) => {
  return {
    person: {
      given_name: values["givenName"],
      family_name: values["familyName"],
      // custom_fields: { phone: values["phone"] },
      postal_addresses: [{ postal_code: values["postalCode"] }],
      email_addresses: [{ address: values["email"] }],
    },
  }
}
