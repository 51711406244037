export const LogLevel = {
  Info: 0,
  Warn: 1,
  Error: 2,
}

export class Logger {
  private identifier: string
  private logLevel: number

  constructor(identifier: string, logLevel: number = LogLevel.Warn) {
    this.identifier = identifier
    if (process.env.GATSBY_VERBOSE) {
      this.logLevel = LogLevel.Info
    } else {
      this.logLevel = logLevel
    }
  }

  public info: typeof console.info = (message, ...optionalParams) => {
    if (this.logLevel <= LogLevel.Info) {
      // eslint-disable-next-line no-console
      console.info(`${this.identifier}: ${message}`, ...optionalParams)
    }
  }

  public log: typeof console.log = (message, ...optionalParams) => {
    if (this.logLevel <= LogLevel.Info) {
      // eslint-disable-next-line no-console
      console.log(`${this.identifier}: ${message}`, ...optionalParams)
    }
  }

  public warn: typeof console.warn = (message, ...optionalParams) => {
    if (this.logLevel <= LogLevel.Warn) {
      // eslint-disable-next-line no-console
      console.warn(`${this.identifier}: ${message}`, ...optionalParams)
    }
  }

  public error: typeof console.error = (message, ...optionalParams) => {
    if (this.logLevel <= LogLevel.Warn) {
      // eslint-disable-next-line no-console
      console.error(`${this.identifier}: ${message}`, ...optionalParams)
    }
  }
}
